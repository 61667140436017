import * as React from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { reduxForm, Field, FormErrors, FormProps, InjectedFormProps, WrappedFieldProps, BaseFieldProps } from 'redux-form';

/* Generic components */
import SpinnerButton from 'components/SpinnerButton/SpinnerButton';

/* Nested components */
import LoginInputWrapper from './LoginInputWrapper';

/* Data */
import { IRootState } from 'store';
import { ISessionValues } from 'data/session/sessionModels';
import { authenticateLogin } from 'data/session/sessionActions';
import { routes } from 'services/routing';
import { CustomFormField } from 'services/forms';
import { RequestStatus } from 'data/request/requestModels';


interface IFormData extends ISessionValues {}
interface IStateToProps {
  loginRequestedStatus: RequestStatus;
}

const LoginFormRxForm: React.StatelessComponent<InjectedFormProps & IStateToProps> = (props) => (
  <form className='login__form' onSubmit={props.handleSubmit}>
    <Field
      label='E-mail'
      id='username'
      name='username'
      type='input'
      component={LoginInputWrapper}
      className='login__input'
    />

    <Field
      label='Wachtwoord'
      id='password'
      name='password'
      type='password'
      component={LoginInputWrapper}
      className='login__input'
    />

    {props.loginRequestedStatus === RequestStatus.FAILED && (
      <div className='login__message--error'>
        Het inlogverzoek is mislukt. Controleer uw inloggegevens.
      </div>
    )}

    <SpinnerButton
      activate={props.loginRequestedStatus === RequestStatus.IN_PROGRESS}
      type='submit'
      className='login__submit'
    >Inloggen</SpinnerButton>
  </form>
);


const mapStateToProps = (state: IRootState): IStateToProps => ({
  loginRequestedStatus: state.session.loginRequestStatus,
});

/* redux-form onSubmit handler */
const handleLogin = (values: IFormData, dispatch: Dispatch<IRootState>): void => {
  dispatch(authenticateLogin(values as IFormData));
};

/* redux-form validation */
const validate = (values: Readonly<IFormData>): FormErrors<IFormData> => {
  const errors: FormErrors<IFormData> = {};

  if (values.username === undefined) {
    errors.username = 'Vul een geldig e-mailadres in';
  }

  if (values.password === undefined) {
    errors.password = 'fill in a password';
  }

  return errors;
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'login',
    onSubmit: handleLogin,
    validate,
  }),
)(LoginFormRxForm);

