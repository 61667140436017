import * as React from 'react';

import { IConfigState } from 'data/config/configReducer';
import { IRootState } from 'store';
import { connect } from 'react-redux';

export interface InjectedConfigProps {
  config: IConfigState;
}

function withConfiguration<P>(
  Component: React.ComponentClass<P & InjectedConfigProps> | React.SFC<P & InjectedConfigProps>
): React.ComponentClass<P> {
  class WithConfigurationEnhancer extends React.Component<P & InjectedConfigProps> {
    public render(): JSX.Element {
      return (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = (state: IRootState, props: any): P & InjectedConfigProps => ({
    ...props,
    config: state.config,
  });

  return connect<P, any, any>(mapStateToProps)(WithConfigurationEnhancer);
}

export default withConfiguration;
