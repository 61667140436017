import * as React from 'react';

import { ReportType } from 'data/reports/reportsModels';

/* Generic components */
import ListPage from 'components/list/ListPage/ListPage';
import ListHeader from 'components/list/ListHeader';
import ListTitle from 'components/list/ListTitle';
import ListNav from 'components/list/ListNav';
import ListNavItem from 'components/list/ListNavItem';
import ListBody from 'components/list/ListBody';
import ListSkeleton from 'components/list/ListSkeleton';
import Button from 'components/Button/Button';
import FilterActionDropdown from 'components/Filter/FilterActionDropdown/FilterActionDropdown';
import FilterAction from 'components/Filter/FilterAction/FilterAction';


/* Specific components */
import Report from './Report';

import ReportsFilterYear from './components/ReportsFilterYear';
import ActiveReportsFilter from './components/ActiveReportsFilter';

import './Reports.less';


interface IStateToProps {
  year: number;
}

class Reports extends React.Component {
  public render(): JSX.Element {
    return (
      <ListPage>
        <ListHeader>
          <ListTitle>Rapportage</ListTitle>
          <ListNav>
            <ListNavItem>
              <FilterActionDropdown buttonStyling='actionBtn actionBtn--filter btn--outline' buttonText='Filter op'>
                <FilterAction title='Jaar'>
                  <ReportsFilterYear/>
                </FilterAction>
              </FilterActionDropdown>
            </ListNavItem>
          </ListNav>
        </ListHeader>
        <ActiveReportsFilter />
        <ListBody className='reports'>
          <Report type={ReportType.active} title='Actieve gebruikers' className='report--no-total'/>
          <Report type={ReportType.added} title='Toegevoegde gebruikers' />
          <Report type={ReportType.removed} title='Verwijderde gebruikers' />
        </ListBody>
      </ListPage>
    );
  }
}

export default Reports;
