import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import RootApp from 'scenes/RootApp/RootApp';
import { store } from 'store';
import { setConfiguration } from 'data/config/configActions';


/* DOM element to render the application within */
const target = document.getElementById('root');


/**
 * Renders the main application.
 *
 * @param Component Instance of the RootApp component
 */
const render = (): void => {
  ReactDOM.render(
    <Provider store={store}>
      <RootApp />
    </Provider>,
    target,
  );
};


/**
 * Set the initial configuration as defined in the config.json
 * which is located at /config/config.json. This path is defined by
 * Webpack. See 'webpack.config.base.js' for the path.
 * Render the application after the dispatching is done.
 */
async function doInitialSetup(): Promise<void> {
  await store.dispatch(setConfiguration);

  return Promise.resolve();
}

doInitialSetup().then(render);
