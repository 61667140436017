import * as React from 'react';

/* Meta data */
import { IUser } from 'data/users/usersModels';

/* Generic components */
import ListItem from 'components/list/ListItem';
import ListItemColumn from 'components/list/ListItemColumn';
import ListActionDropdown from 'components/list/ListActionDropdown/ListActionDropdown';
import Checkbox from 'components/Checkbox/Checkbox';
import MomentDate from 'components/MomentDate';

/* Nested components */
import UserListItemFilter from './UserListItemFilter/UserListItemFilter';
import { List } from 'data/lists/listsModels';
import withAuthorization, { InjectedAuthProps } from 'components/hoc/withAuthorization';

interface IProps {
  itemNr: number;
  user: IUser;
}

const UsersListItem: React.StatelessComponent<IProps & InjectedAuthProps> = ({ itemNr, user, authorized }) => (
  <ListItem>
    <ListItemColumn width={5}>
      {itemNr}
    </ListItemColumn>

    <ListItemColumn width={authorized ? 23 : 23}>
      <a href='#'>{user.firstName} {user.lastName}</a>
    </ListItemColumn>

    <ListItemColumn width={22}>
      <span className='list--subtxt'>{user.location}</span>
    </ListItemColumn>

    <ListItemColumn width={13}>{user.organisation}</ListItemColumn>

    <ListItemColumn width={17}>
      <span className='list--subtxt'>{user.username}</span>
    </ListItemColumn>

    <ListItemColumn width={12}>
      <MomentDate time={user.registrationDate} format='DD-MM-YYYY' />
    </ListItemColumn>

    <ListItemColumn width={12}>
      <MomentDate time={user.lastActiveDate} format='DD-MM-YYYY' />
    </ListItemColumn>

    {authorized && (
      <ListItemColumn width={5} className='list__item-col--action'>
        <ListActionDropdown buttonStyling='actionBtn--list'>
          {user.id && <UserListItemFilter id={user.id} />}
        </ListActionDropdown>
      </ListItemColumn>
    )}
  </ListItem>
);

export default withAuthorization(UsersListItem);
