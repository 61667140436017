/* tslint:disable:no-null-keyword */

import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { IRootState } from 'store';

interface ILoggedIn {
  loggedIn: boolean;
}

export function privateComponent<P>(
  Component: React.ComponentClass<P> | React.StatelessComponent<P>
): React.ComponentClass<P> {
  class PrivateRouteComponent extends React.Component<P & ILoggedIn & DispatchProp<P>> {

    public render(): JSX.Element {
      if (!this.props.loggedIn) {
        return null;
      }

      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = (state: IRootState, props: any): P => ({
    ...props,
    loggedIn: state.session.loggedIn,
  });

  return connect<P, any, any>(mapStateToProps)(PrivateRouteComponent);
}

export default privateComponent;
