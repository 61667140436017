import * as React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'services/routing';
import privateComponent from 'components/privateComponent';
import SvgIcon from 'components/SvgIcon';

import TabNav from './TabNav/TabNav';
import SearchBar from 'components/SearchBar/SearchBar';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './TabBar.less';
import { List } from 'data/lists/listsModels';

interface IProps {
  list: List;
}

const TabBar: React.StatelessComponent<RouteComponentProps<void>> = ({
  location
}) => {
  const routesWithSearchBar = [routes.Root, routes.Archive];
  const showSearchBar = routesWithSearchBar.find((route) => route === location.pathname);

  return(
    <section className='tabBar'>
      <div className='container'>
        <div className='tabBar__inner'>
          <div className='tabBar__nav'>
            <TabNav />
          </div>

          <div className='tabBar__search'>
            {showSearchBar && <SearchBar />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(privateComponent(TabBar));
