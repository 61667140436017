import * as React from 'react';
import request, { IRequestProps } from 'components/requests/request';
import RequestComponent from 'components/requests/RequestComponent';
import withRequestByProp from 'components/requests/requestByProp';
import { IUser } from 'data/users/usersModels';

import './UserListItemAction.less';
import ListActionDropdown from 'components/list/ListActionDropdown/ListActionDropdown';
import UserListItemFilterTime from './UserListItemFilterTime';
import UserListItemFilterList from './UserListItemFilterList';
import UserListItemDropfilter from './UserListItemDropfilter';


interface IProps {
  id: number;
}

/**
 * Add the class 'dropFilter__component--collapsed to get a plus icon
 */

const UserListItemFilter: React.StatelessComponent<IProps> = ({ id }) => {
  return (
    <div className='dropFilter dropFilter--user'>
      <UserListItemDropfilter title='Categorie' open>
        <UserListItemFilterList id={id} />
      </UserListItemDropfilter>
      <UserListItemDropfilter title='Tijdsbeperking'>
        <UserListItemFilterTime userId={id} />
      </UserListItemDropfilter>
    </div>
  );
};

export default UserListItemFilter;
