import * as axios from 'axios';

import { ISessionState, initialState as initialSessionState } from './sessionReducer';
import { ISessionValues, IAuthenticated } from './sessionModels';
import { IjwtPayload } from 'services/auth';
import { RequestStatus } from '../request/requestModels';


export const setLoginRequested = (state: ISessionState) => ({
  ...state,
  loginRequestStatus: RequestStatus.IN_PROGRESS,
});

export const setLoginSuccess = (state: ISessionState, payload: IAuthenticated) => ({
  loginRequestStatus: RequestStatus.SUCCESS,
  loggedIn: true,
  userData: {
    ...payload.jwtPayload,
    readonly: true,
  }
});

export const setLoginFailed = (state: ISessionState) => ({
  ...state,
  loginRequestStatus: RequestStatus.FAILED,
});


export const setLoggedOut = (state: ISessionState) => ({
  ...initialSessionState,
});
