import * as React from 'react';
import { Link } from 'react-router-dom';

/* Services / Data */
import { routes } from 'services/routing';
import { ThemeContext, primaryThemeCSS } from 'services/theming';
import { IConfigState } from 'data/config/configReducer';

/* Global components */
import privateComponent from 'components/privateComponent';

/* Nested components */
import HeaderUserDropdown from './components/HeaderUserDropdown';

/* Stylesheets */
import './Header.less';


const HeaderMain = privateComponent(() => (
  <div className='header__main'>
    <div className='header__main-logo'>
      <div className='headerLogo'>
        <Link to={routes.Root}>
          <ThemeContext.Consumer>
            {(theme) => (
              <img
                src='/config/images/logo.png'
                alt={`${theme.title} logo`}
                className='headerLogo__img'
              />
            )}
          </ThemeContext.Consumer>
        </Link>
      </div>
    </div>
    <div className='header__main-nav'>
      <nav className='iconNav'>
        <HeaderUserDropdown />
      </nav>
    </div>
  </div>
));


const Header: React.StatelessComponent = (props) => (
  <header className='header'>
    <ThemeContext.Consumer>
      {(theme) => (
        <div className='header__top' style={primaryThemeCSS(theme)}>
          <span className='header__top-title'>{theme.title}</span>
        </div>
      )}
    </ThemeContext.Consumer>
    <HeaderMain />
  </header>
);

export default Header;
