import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import validateRoute from 'components/validateRoute';

const PrivateRoute: React.StatelessComponent<RouteProps> = ({ component, ...rest }) => (
  <Route {...rest} render={(props) => {
      const Component = validateRoute(component);
      return <Component {...props} />;
    }}
  />
);

export default PrivateRoute;
