import * as React from 'react';

import './ListPage.less';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

const ListPage: React.StatelessComponent<IProps> = ({ children }) => (
  <section className='list'>
    <div className='container'>
      {children}
    </div>
  </section>
);

export default ListPage;
