import { IRootState } from 'store';
import { Dispatch } from 'react-redux';

import { ActionType, IPureAction } from 'data/actions';

interface ISearchTerm {
  value: string;
}

export const setSearchValue = (value: ISearchTerm): IPureAction<ActionType.SET_SEARCH_TERM, ISearchTerm> => ({
  type: ActionType.SET_SEARCH_TERM,
  payload: value
});