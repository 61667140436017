import * as React from 'react';
import withRequest, { IRequestProps } from 'components/requests/request';
import { connect } from 'react-redux';
import PaginatedRequestComponent from 'components/requests/PaginatedRequestComponent';

/* Data */
import { IRootState } from 'store';
import { IUser } from 'data/users/usersModels';
import Config from 'constants/Config';
import { List, IFilter } from 'data/lists/listsModels';
import { IPaginationHeaders, IPaginationOptions, IQueryStringOptions } from 'services/pagination';

/* Generic components */
// import ListActiveFilters from 'components/list/ListActiveFilters';
import ListPage from 'components/list/ListPage/ListPage';
import ListHeader from 'components/list/ListHeader';
import ListTitle from 'components/list/ListTitle';
import ListBody from 'components/list/ListBody';
import Button from 'components/Button/Button';
import ListSkeleton from 'components/list/ListSkeleton';

/* Nested components */
import UsersListHead from './components/UsersListHead';
import UsersList from './components/UsersList';
import UserFilterDate from './components/UserFilterDate';


interface IStateToProps {
  searchQueryValue: string;
}

interface IState extends IQueryStringOptions {}
interface IProps extends IStateToProps {}

const initialState: IState = {
  offset: undefined,
  limit: Config.PAGINATION_CAP,
  filter: undefined,
  order_col: 'last_seen',
  order: 'asc',
};

class Users extends PaginatedRequestComponent<IProps, IState> {
  public state: IState = initialState;

  protected renderInProgress(): JSX.Element {
    const { request } = this.props;

    if (request.data && request.data.length > 0) {
      return (
        <div className='request request--is-loading'>
          {this.renderSuccess(request.data, request.headers)}
        </div>
      );
    }

    return <ListSkeleton count={Config.PAGINATION_CAP} />;
  }
  
  protected renderFailure(): JSX.Element {
    return <div>failed to fetch</div>;
  }
  
  protected renderSuccess(data: IUser[], headers: IPaginationHeaders): JSX.Element {
    if (data && data.length === 0) {
      return <div className='list__empty'>Er zijn geen gebruikers om te weergeven.</div>;
    }

    return (
      <UsersList users={data} currentPage={Number(headers['x-current-page'])}/>
    );
  }

  protected onPaginate(options: IPaginationOptions): void {
    this.setState((prevState) => ({
      ...prevState,
      ...options,
    }));
  }

  private handleSort(column: string, direction: 'asc' | 'desc'): void {
    this.setState((prevState) => ({
      ...prevState,
      offset: undefined,
      order_col: column,
      order: direction,
    }));
  }

  public componentWillReceiveProps(nextProps: IProps): void {
    if (this.props.searchQueryValue.length !== nextProps.searchQueryValue.length) {
      this.setState((prevState) => ({
        ...prevState,
        filter: nextProps.searchQueryValue,
        limit: initialState.limit,
        offset: undefined,
      }));
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState): void {
    if (prevState !== this.state) {
      this.doRequest(this.state);
    }
  }

  
  public render(): JSX.Element {
    const { data, headers } = this.props.request;
    
    return (
      <ListPage>
        <ListHeader>
          <ListTitle quantity={headers['x-total-count'] || 0}>Gebruikers</ListTitle>
        </ListHeader>
        <ListBody>
          <UsersListHead
            onSort={this.handleSort.bind(this)}
            sortColumn={this.state.order_col}
            sortDirection={this.state.order}
          />
          {super.render()}
        </ListBody>
      </ListPage>
    );
  }
}

const mapStateToProps = (state: IRootState): IStateToProps => ({
  searchQueryValue: state.searchterm,
});

export default withRequest({
  url: '/users',
  qs: {
    offset: 0,
    limit: 30,
    order_col: 'last_seen',
    order: 'desc',
  },
}, connect(mapStateToProps)(Users));
