import * as React from 'react';
import { IConfigState } from 'data/config/configReducer';

const branding: IConfigState = {
  title: 'Ontzorg.net',
  primary: {
    background: '#002C5B',
    text: 'white',
  },
  secondary: {
    background: '#BFCE02',
    text: 'white',
  }
};

export const ThemeContext = React.createContext<IConfigState>(branding);

export const primaryThemeCSS = (theme: IConfigState): React.CSSProperties => ({
  backgroundColor: theme.primary.background,
  color: theme.primary.text,
});

export const secondaryThemeCSS = (theme: IConfigState): React.CSSProperties => ({
  backgroundColor: theme.secondary.background,
  color: theme.secondary.text,
});