import * as React from 'react';
import { hot } from 'react-hot-loader';

/* Routing */
import { Link, Route, Router } from 'react-router-dom';
import { routes, history } from 'services/routing';

/* Scenes */
import Users from 'scenes/Users/Users';
import Reports from 'scenes/Reports/Reports';
import Login from 'scenes/Login/Login';
import InactiveUsers from 'scenes/InactiveUsers/InactiveUsers';

/* Nested components */
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import TabBar from './components/TabBar/TabBar';
import PrivateRoute from './components/PrivateRoute';

/* Stylesheet */
import 'styles/main.less';
import ContextWrapper from './components/ContextWrapper';


const RootApp = () => (
  <ContextWrapper>
    <Router history={history}>
      <div className='wrapper'>
        <Header />
        <div className='app'>
          <TabBar />
          <Route exact path={routes.Login} component={Login} />
          <PrivateRoute exact path={routes.Root} component={Users} />
          <PrivateRoute exact path={routes.Reports} component={Reports} />
          <PrivateRoute exact path={routes.InactiveUsers} component={InactiveUsers} />
        </div>
        <Footer />
      </div>
    </Router>
  </ContextWrapper>
);

export default hot(module)(RootApp);
