import { IRequestProps } from 'components/requests/request';
import { IQueryStringOptions } from 'services/pagination';

export enum RequestStatus {
  IN_PROGRESS,
  SUCCESS,
  FAILED,
}

export interface IRequestOptions {
  url: string;
  qs?: IQueryStringOptions;
}

export interface IRouteRequestOptions extends IRequestOptions { }

export interface IPropRequestOptions extends IRequestOptions { }

export type RequestHoC<P> = React.ComponentClass<P & IRequestProps> | React.SFC<P & IRequestProps>;
