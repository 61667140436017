import * as React from 'react';

import './Footer.less';
import { ThemeContext, primaryThemeCSS } from 'services/theming';


const Footer: React.StatelessComponent = () => (
  <ThemeContext.Consumer>
    {(theme) => (
      <footer className='footer' style={primaryThemeCSS(theme)}>
        <span className='footer__copy'>&copy; 2018 ontzorgnet b.v.</span>
      </footer>
    )}
  </ThemeContext.Consumer>
);

export default Footer;
