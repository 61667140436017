import * as React from 'react';
import * as classnames from 'classnames';

import { CustomFormField } from 'services/forms';


const LoginInputWrapper: React.StatelessComponent<CustomFormField> = (props) => {
  const { input, label, type, meta: { touched, error, warning } } = props;

  const styling = classnames({
    'login__input-wrapper': true,
    'login__input-wrapper--error': touched && error,
    'login__input-wrapper--warning': touched && warning,
  });

  return (
    <div className={styling}>
      <label htmlFor={props.id} className='login__input-label'>{props.label}</label>
      <input {...props.input} id={props.id} name={props.name} type={props.type} className='login__input' />
      {touched && ((error && (
        <div className='login__error'>
          <span className='login__error-icon'>i</span>
          <div className='login__error-flyout'>
            {error}
          </div>
        </div>
      )) || (warning && (
        <div className='login__error'>
          <span className='login__error-icon'>i</span>
          <div className='login__error-flyout'>
            {warning}
          </div>
        </div>
      )))}
    </div>
  );
};

export default LoginInputWrapper;
