import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'store';

export interface InjectedAuthProps {
  authorized: boolean;
}

function withAuthorization<P>(
  Component: React.ComponentClass<P & InjectedAuthProps> | React.SFC<P & InjectedAuthProps>
): React.ComponentClass<P> {
  class WithAuthorizationEnhancer extends React.Component<P & InjectedAuthProps> {
    public render(): JSX.Element {
      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = (state: IRootState, props: any): P & InjectedAuthProps => ({
    ...props,
    authorized: !state.session.userData.readonly,
  });

  return connect<P, any, any>(mapStateToProps)(WithAuthorizationEnhancer);
}

export default withAuthorization;
