import * as React from 'react';
import ListItem from 'components/list/ListItem';
import ListItemColumn from 'components/list/ListItemColumn';
import { IUser } from 'data/users/usersModels';
import Checkbox from 'components/Checkbox/Checkbox';
import MomentDate from 'components/MomentDate';
import withAuthorization, { InjectedAuthProps } from 'components/hoc/withAuthorization';

interface IProps {
  itemNr: number;
  checked: boolean;
  onToggle(): any;
  user: IUser;
}

const InactiveUsersListItem: React.StatelessComponent<IProps & InjectedAuthProps> = ({ itemNr, checked, onToggle, user, authorized }) => (
  <ListItem>
    {authorized && (
      <ListItemColumn width={4}>
        <Checkbox id={`inactive-user-${itemNr}`} onClick={() => onToggle()} checked={checked} />
      </ListItemColumn>
    )}
    <ListItemColumn width={5}>
      {itemNr}
    </ListItemColumn>
    <ListItemColumn width={authorized ? 28 : 33}>
      <a href='#'>{user.firstName} {user.lastName}</a>
    </ListItemColumn>

    <ListItemColumn width={25}><span className='list--subtxt'>{user.location}</span></ListItemColumn>
    <ListItemColumn width={14}>{user.organisation}</ListItemColumn>
    <ListItemColumn width={12}>
      <MomentDate time={user.registrationDate} format='DD-MM-YYYY' />
    </ListItemColumn>
    <ListItemColumn width={12}>
      <MomentDate time={user.lastActiveDate} format='DD-MM-YYYY' />
    </ListItemColumn>
  </ListItem>
);

export default withAuthorization(InactiveUsersListItem);
