import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import TabNavItem from './TabNavItem';

interface IProps {
  route: string;
  children?: any;
  className?: any;
}

const RouteTabNavItem: React.StatelessComponent<IProps & RouteComponentProps<IProps>> = ({
  location,
  className,
  route,
  children
}) => (
  <TabNavItem className={className} to={route} isActive={location.pathname === route}>
    {children}
  </TabNavItem>
);

export default withRouter(RouteTabNavItem);