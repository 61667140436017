import * as React from 'react';
import * as classnames from 'classnames';

/* Stylesheet */
import './SpinnerButton.less';
import { ThemeContext, primaryThemeCSS } from 'services/theming';

/* This component is WIP - CSS is incomplete */


interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  activate?: boolean;
}

const SpinnerButton: React.StatelessComponent<IProps> = ({ className, children, activate, ...rest }) => {
  const styling = classnames({
    [className]: Boolean(className),
    'spinner-btn': true,
    'spinner-btn--running': activate,
  });

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <button className={styling} {...rest} style={primaryThemeCSS(theme)}>
          {children}
        </button>
      )}
    </ThemeContext.Consumer>
  );
};

export default SpinnerButton;
