import * as React from 'react';

interface IProps {
  count: number;
}

const ListSkeleton: React.StatelessComponent<IProps> = ({ count }): JSX.Element => {

  const list = [];

  for (let i = 0; i < count; i++) {
    list.push(
    <li key={i} className='list__item list__item--skeleton'>
      <div className='list__item-col span-4'>
        <span className='list__checkbox--skeleton' />
      </div>
      <div className='list__item-col span-9'>
        <span className='list__xsmall--skeleton' />
      </div>
      <div className='list__item-col span-51'>
        <span className='list__large--skeleton' />
        <span className='list__medium--skeleton' />
      </div>
      {/* <div className='list__item-col span-10'>
        <span className='list__xsmall--skeleton' />
      </div> */}
      <div className='list__item-col span-11'>
        <span className='list__xsmall--skeleton' />
      </div>
      <div className='list__item-col span-12'>
        <span className='list__small--skeleton' />
      </div>
      <div className='list__item-col span-12'>
        <span className='list__small--skeleton' />
      </div>
      <div className='list__item-col span-5'>
        <span className='list__hamburger--skeleton' />
      </div>
    </li>
    );
  }

  return <div className='request request--is-loading'>{list}</div>;
};

export default ListSkeleton;