import { IAction, ActionType } from 'data/actions';
import { List, IListFilters } from './listsModels';
import * as listsMutators from './listsMutators';

import * as defaultListData from './listDefaults';

export interface IListsState {
  [key: string]: IListFilters;
}

export const initialState: IListsState = {
  [List.Users]: {
    organisation: {
      activeIds: [],
      data: [],
      type: 'checkbox',
    },
  },
  [List.Reports]: {
    year: {
      activeIds: [4],
      data: defaultListData.reportYears,
      type: 'radio',
    },
  },
};

export const handlers: { [index: number]: any } = {
  [ActionType.SET_ORGANISATIONS]: listsMutators.fillOrganisationFilterData,
  [ActionType.TOGGLE_LIST_ITEM]: listsMutators.setToggleListItem,
};

export function listsReducer(state: IListsState = initialState, action: IAction<any>): IListsState {
  if (handlers[action.type]) {
    return handlers[action.type](state, action.payload);
  }

  return state;
}