import * as React from 'react';

import RequestComponent from './RequestComponent';
import { IPaginationHeaders, IPaginationOptions } from 'services/pagination';
import { RequestStatus } from 'data/request/requestModels';
import { IQueryStringOptions } from 'services/pagination';

import ListPagination from 'components/list/ListPagination';

export default abstract class PaginatedRequestComponent<P = {}, S = {}> extends RequestComponent<P, S> {
  private renderPaginatedComponent(callback: () => JSX.Element): JSX.Element {
    const { headers } = this.props.request;

    return (
      <div>
        {callback()}
        <ListPagination
          currentPage={Number(headers['x-current-page']) || 0}
          total={Number(headers['x-total-count']) || 0}
          onPaginate={this.onPaginate.bind(this)}
        />
      </div>
    );
  }

  public render(): JSX.Element {
    switch (this.props.request.requestStatus) {
      case RequestStatus.IN_PROGRESS: return this.renderPaginatedComponent(() => this.renderInProgress());
      case RequestStatus.SUCCESS: return this.renderPaginatedComponent(() => this.renderSuccess(this.props.request.data, this.props.request.headers));
      case RequestStatus.FAILED: return this.renderFailure();
      default: throw new Error('Something went wrong, got no requestStatus');
    }
  }

  protected abstract renderInProgress(): JSX.Element;
  protected abstract renderSuccess(data: any, headers: any): JSX.Element;
  protected abstract renderFailure(): JSX.Element;
  protected abstract onPaginate(options: IPaginationOptions): any;
}
