import * as React from 'react';
import * as classnames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

interface IProps extends LinkProps {
  isActive?: boolean;
}

const TabNavItem: React.StatelessComponent<IProps> = ({ isActive = false, children, className, ...rest }) => {
  const styling = classnames({
    tabNav__item: true,
    active: isActive,
    [className]: Boolean(className)
  });

  return (
    <Link className={styling} {...rest}>
      {children}
    </Link>
  );
};

export default TabNavItem;
