import { IAction, ActionType } from 'data/actions';
import { IOrganisation } from './organisationsModels';


export const initialState: IOrganisation[] = [];

const handlers: { [index: number]: any } = {
  [ActionType.SET_ORGANISATIONS]: (state: IOrganisation[], organisations: IOrganisation[]) => organisations,
};

export function organisationsReducer(state: IOrganisation[] = initialState, action: IAction<any>): IOrganisation[] {
  if (handlers[action.type]) {
    return handlers[action.type](state, action.payload);
  }

  return state;
}