import { IRootState } from 'store';
import { Dispatch } from 'react-redux';

import { ActionType, IPureAction } from 'data/actions';
import { IToggleFilterPayload } from './listsModels';

export const toggleListItem = (payload: IToggleFilterPayload): IPureAction<ActionType.TOGGLE_LIST_ITEM, IToggleFilterPayload> => ({
  type: ActionType.TOGGLE_LIST_ITEM,
  payload,
});
