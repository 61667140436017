import * as React from 'react';

import LoginForm from 'scenes/Login/components/LoginForm';

import './Login.less';


const Login: React.StatelessComponent = () => {
  return (
    <div className='login'>
      <h1 className='login__title'>Internet op maat</h1>
      <div className='login__inner'>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;