export interface IReports {
  header: any[];
  rows: any[];
  totals: number[];
}

/**
 * Types that are used to determine the API endpoint.
 * See Report.tsx for an implementation.
 * 
 * Example: /reports/totals/{ReportType/{Year}}
 */
export enum ReportType {
  added = 'added',
  removed = 'removed',
  active = 'active',
}
