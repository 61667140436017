import { applyMiddleware, combineReducers, createStore, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer as formReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';

import { IOrganisation } from 'data/organisations/organisationsModels';
import { ActionType } from 'data/actions';
import { IListsState, listsReducer } from 'data/lists/listsReducer';
import { ISessionState, sessionReducer } from 'data/session/sessionReducer';
import { IFilterState, filterReducer } from 'data/filters/filtersReducer';
import { organisationsReducer } from 'data/organisations/organisationsReducer';
import { searchbarReducer } from 'data/searchbar/searchbarReducer';
import { IConfigState, configReducer } from 'data/config/configReducer';

import sagas from 'data/sagas';

export interface IRootState {
  config: IConfigState;
  lists: IListsState;
  searchterm: string;
  session: ISessionState;
  filters: IFilterState;
  organisations: IOrganisation[];
}

const actionTypeEnumToString = (action: any): any => typeof action.type === 'number' && ActionType[action.type] ? ({
  type: ActionType[action.type],
  payload: action.payload,
}) : action;

const logger = createLogger({ actionTransformer: actionTypeEnumToString });
const composeEnhancers = composeWithDevTools({ actionSanitizer: actionTypeEnumToString });

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  combineReducers({
    config: configReducer,
    form: formReducer,
    lists: listsReducer,
    searchterm: searchbarReducer,
    session: sessionReducer,
    filters: filterReducer,
    organisations: organisationsReducer,
  }),
  composeEnhancers(
    applyMiddleware(
      thunk,
      sagaMiddleware,
      logger,
    )
  )
);

sagaMiddleware.run(sagas);

