import * as React from 'react';

interface IProps extends React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> { }

const ListHead: React.StatelessComponent<IProps> = ({ children }) => (
  <li className='list__head'>
    {children}
  </li>
);

export default ListHead;
