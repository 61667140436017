import * as React from 'react';
import * as classnames from 'classnames';


interface IProps extends React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> { }

const ListItem: React.StatelessComponent<IProps> = ({ children, className, ...rest }) => {
  const styling = classnames({
    list__item: true,
    [className]: Boolean(className),
  });

  return (
    <li className={styling} {...rest}>
      {children}
    </li>
  );
};

export default ListItem;