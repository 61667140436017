import * as React from 'react';
import * as classnames from 'classnames';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement> {} 

const ListBody: React.StatelessComponent<IProps> = ({ className, children, ...rest }) => {
  const styling = classnames({
    list__body: true,
    [className]: Boolean(className),
  });

  return (
    <ul className={styling} {...rest}>
      {children}
    </ul>
  );
};

export default ListBody;
