import * as React from 'react';
import * as classnames from 'classnames';

import './Button.less';
import SvgIcon from 'components/SvgIcon';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: string;
  iconClass?: string;
  iconPosition?: 'left' | 'right';
  children?: any;
}

const Button: React.StatelessComponent<IProps> = ({ icon, iconPosition, iconClass, children, className, ...rest }) => {
  const styling = classnames({
    btn: true,
    [className]: Boolean(className)
  });

  return (
    <button className={styling} {...rest} >
      {icon && iconPosition === 'left' && <span className={iconClass}><SvgIcon icon={icon} /></span>}
      {children}
      {icon && iconPosition === 'right' && <span className={iconClass}><SvgIcon icon={icon} /></span>}
    </button>
  );
};

export default Button;