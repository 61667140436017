import { IFilterData } from './listsModels';

export const reportYears: IFilterData[] = [
  {
    id: 1,
    label: '2015',
    data: '2015',
  },
  {
    id: 2,
    label: '2016',
    data: '2016',
  },
  {
    id: 3,
    label: '2017',
    data: '2017',
  },
  {
    id: 4,
    label: '2018',
    data: '2018',
  },
];

export const reportActivity: IFilterData[] = [
  {
    id: 1,
    label: 'Actief',
    data: 1000,
  },
  {
    id: 2,
    label: 'Verwijderd',
    data: 0,
  }
];
