import * as React from 'react';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

const ListHeader: React.StatelessComponent<IProps> = ({ children }) => (
  <div className='list__header'>
    {children}
  </div>
);

export default ListHeader;
