import { IAction, ActionType } from 'data/actions';
import { IConfiguration } from './configModels';

import * as configMutators from './configMutators';

export interface IConfigState extends IConfiguration { }

export const initialState: IConfigState = {
  title: '',
  primary: {
    background: '',
    text: '',
  },
  secondary: {
    background: '',
    text: '',
  },
};

const handlers: { [index: number]: any } = {
  [ActionType.SET_CONFIGURATION]: configMutators.setConfiguration,
};

export function configReducer(state: IConfigState = initialState, action: IAction<any>): IConfigState {
  if (handlers[action.type]) {
    return handlers[action.type](state, action.payload);
  }

  return state;
}
