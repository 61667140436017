import { IPureAction, ActionType } from 'data/actions';
import { ThunkAction } from 'redux-thunk';
import { IRootState } from 'store';

import { fetchConfigFileContentsByHTTP } from './configAPI';


export const setConfiguration: ThunkAction<Promise<void>, IRootState, void> = (dispatch) => (
  new Promise<void>(async (resolve) => {
    const configuration = await fetchConfigFileContentsByHTTP();
    dispatch({ type: ActionType.SET_CONFIGURATION, payload: configuration });
    resolve();
  })
);
