import axios, { AxiosRequestConfig, AxiosPromise, AxiosInstance } from 'axios';
import LocalStorageManager from 'services/LocalStorageManager';

export default class API {
  public static client: AxiosInstance = axios.create({
    baseURL: BACKEND_URL,
    responseType: 'json',
    headers: {
      'Accept': 'application/json',
      'X-Access-Token': LocalStorageManager.getJwt() || undefined,
    },
  });

  public static refreshToken(): void {
    API.client.defaults.headers['X-Access-Token'] = `Bearer ${LocalStorageManager.getJwt()}`;
  }

  public static removeToken(): void {
    API.client.defaults.headers['X-Access-Token'] = undefined;
  }

  public static fetch(url: string): AxiosPromise {
    return API.client.get(url);
  }

  public static post(url: string, payload: any): AxiosPromise {
    return API.client.post(url, payload);
  }

  public static put(url: string, payload?: any): AxiosPromise {
    return API.client.put(url, payload);
  }

  public static delete(url: string): AxiosPromise {
    return API.client.delete(url);
  }
}