import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classnames from 'classnames';
import { connect, Dispatch } from 'react-redux';

/* Redux */
import { IRootState } from 'store';
import { logout } from 'data/session/sessionActions';

/* Generic components */
import SvgIcon from 'components/SvgIcon';
import { ThemeContext, secondaryThemeCSS } from 'services/theming';
import { hexToRgba } from 'services/util';


interface IStateToProps {
  name: string;
  last_login: string;
}

interface IDispatchToProps {
  logout(): void;
}

interface IState {
  active: boolean;
}

interface IProps extends IStateToProps, IDispatchToProps { }

class HeaderUserDropdown extends React.Component<IProps, IState> {
  private wrapper: HTMLDivElement;

  public state: IState = {
    active: false,
  };

  private toggleActive = (): void => (
    this.setState({ active: !this.state.active })
  )

  public componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  public componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  public handleClickOutside(event: React.MouseEvent<HTMLElement>): void {
    if (this.wrapper !== null && !this.wrapper.contains(event.target as Node) && this.state.active) {
      this.toggleActive();
    }
  }


  public render(): JSX.Element {
    const navWrapperStyling = classnames({
      'iconNav__wrapper': true,
      'is-open': this.state.active,
    });

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={navWrapperStyling} ref={(c) => { this.wrapper = c; }}>
            <button
              className='iconNav__item'
              onClick={this.toggleActive.bind(this)}
              style={this.state.active ? secondaryThemeCSS(theme) : { color: theme.secondary.background }}
            >
              <SvgIcon icon={this.state.active ? 'circle-close' : 'user'} />
            </button>
            <div className='iconNav__dropdown' style={{ backgroundColor: hexToRgba(theme.primary.background, .89), color: theme.primary.text }}>
              <div className='userDropdown'>
                <div className='userDropdown__info'>
                  <div className='userDropdown__info-icon' style={{ color: theme.secondary.background }}>
                    <SvgIcon icon='user' />
                  </div>
                  <div className='userDetail__info-details'>
                    <div className='userDropdown__info-name'>{this.props.name}</div>
                    <div className='userDropdown__info-login'>Laatste login: {this.props.last_login}</div>
                  </div>
                </div>
                <button className='userDropdown__btn' onClick={this.props.logout} style={{ color: theme.primary.text }}>
                  <span>Uitloggen</span>
                  <span className='userDropdown__btn-icon'>
                    <SvgIcon icon='angle-right' />
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const mapStateToProps = (state: IRootState): IStateToProps => ({
  name: state.session.userData.name,
  last_login: state.session.userData.last_login,
});

const mapDispatchToProps = (dispatch: Dispatch<IRootState>): IDispatchToProps => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserDropdown);
