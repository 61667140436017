import * as React from 'react';
import ListHead from 'components/list/ListHead';
import Checkbox from 'components/Checkbox/Checkbox';
import withAuthorization, { InjectedAuthProps } from 'components/hoc/withAuthorization';

interface IProps {
  onToggleAll(): any;
  toggleAllChecked: boolean;
}

const InactiveUsersListHead: React.StatelessComponent<IProps & InjectedAuthProps> = ({ onToggleAll, toggleAllChecked, authorized }) => (
  <ListHead>
    {authorized && (
      <div className='list__head-col span-4'>
        <Checkbox
          id='inactive-users-check-all'
          onClick={() => onToggleAll()}
          checked={toggleAllChecked || false}
        />
      </div>
    )}
    <div className='list__head-col span-5'>
      #
    </div>
    <div className={`list__head-col span-${authorized ? 28 : 33}`}>
      Naam
    </div>
    <div className='list__head-col span-25'>
      Locatie
    </div>
    <div className='list__head-col span-14'>
      Organisatie
    </div>
    <div className='list__head-col span-12'>
      Registratie
    </div>
    <div className='list__head-col span-12'>
      Laatst actief
    </div>
  </ListHead>
);

export default withAuthorization(InactiveUsersListHead);
