
import * as React from 'react';
import withConfiguration, { InjectedConfigProps } from 'components/hoc/withConfiguration';
import { ThemeContext } from 'services/theming';


const ContextWrapper: React.SFC<InjectedConfigProps> = (props) => (
  <ThemeContext.Provider value={props.config}>
    {props.children}
  </ThemeContext.Provider>
);

export default withConfiguration<{}>(ContextWrapper);
