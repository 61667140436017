import * as React from 'react';

import { IUser } from 'data/users/usersModels';
import UsersListItem from './UsersListItem';
import { IRootState } from 'store';
import Config from 'constants/Config';

interface IProps {
  users: IUser[];
  currentPage: number;
}

const UsersList: React.StatelessComponent<IProps> = ({ users, currentPage  }) => {
  const listItems = users.map((user, index) => {
    const itemNr = (index + 1) + ((currentPage - 1) * Config.PAGINATION_CAP);
    return <UsersListItem key={index} user={user} itemNr={itemNr} />;
  });

  return <div>{listItems}</div>;
};

export default UsersList;
