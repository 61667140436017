import * as React from 'react';
import { IUser } from 'data/users/usersModels';

import './FilterAction.less';
import SvgIcon from 'components/SvgIcon';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string
  children: JSX.Element
}

const FilterAction: React.StatelessComponent<IProps> = ({ children, title }) => {
  return (
    <div className = 'dropFilter__component filter' >
      <div className="filter__title">{title}</div>
      <div className="filter__body">
        {children}
      </div>
    </div>
  )
}

export default FilterAction;